import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo';

interface Props {
  data: {
    markdownRemark: {
      html: string;
    };
  };
}

export default function PrivacyPolicyPage({ data }: Props) {
  const { html } = data.markdownRemark;
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="The Augustine Institute serves the formation of Catholics for the New Evangelization through academic and parish programs."
      />
      <section className="container page-m-t page-m-b">
        <div className="row">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-lg-offset-1">
            {html && (
              <div /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: html }}
                className="text-block"
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { title: { eq: "Privacy Policy" } }) {
      html
    }
  }
`;
